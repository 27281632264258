<template>
  <v-container fluid>
    <PageHeaderSlot :title="pageTitle" showBackButton>
      <template slot="bottom-right-slot">
        <v-btn
          v-if="showDelete"
          type="button"
          class="ml-2"
          outlined
          color="error"
          text
          depressed
          :loading="$store.getters.isLoading"
          @click="handleDeleteThisLesson()"
        >{{ $t('delete') }}</v-btn>
        <v-btn text depressed class="primary ml-2" @click.prevent="save()" :loading="$store.getters.isLoading">{{ $t('save') }}</v-btn>
      </template>
    </PageHeaderSlot>

    <v-row class="ma-0 align-stretch">
      <v-col class="pa-0 mb-4" cols="12" lg="6">
        <v-card class="course-info">
          <div class="d-flex justify-space-between align-center pr-5">
            <v-card-title><span>{{ $t('lessonInfo') }}</span></v-card-title>
            <v-btn depressed text color="primary" :ripple="false" class="pa-0" @click.prevent="editClassSetting()">
              <v-icon class="fi fi-rr-pencil mr-1" size="18"></v-icon>
              {{ $t('editLesson') }}
            </v-btn>
          </div>
         
          <v-card-text class="pb-6 px-0">
            <div class="course-code text-right pa-4 mb-6">{{ $t('courseCode') }} : {{ staticLessonInfo.course_code }}</div>
            <v-form ref="classInfoForm" @submit.prevent>
              <v-row class="ma-0">
                <v-col class="py-0" cols="12" sm="6">
                  <FormDatePicker
                    label="date"
                    :dateValue.sync="formData.date"
                    required
                    disabledActionButtons
                    dense
                    disablePast
                    dispatchUpdateOnChange
                  />
                </v-col>
                <v-col class="py-0" cols="12" sm="6">
                  <label class="input-label">{{ $t('time') }}</label>
                  <div class="d-flex justify-space-between">
                    <FormSelect
                      :fieldValue.sync="formData.start_time"
                      required
                      :options="startTimeOptions"
                      dense
                      @changed="formData.end_time = ''"
                      dispatchUpdateOnChange
                    />
                    <span class="body-2 colorBlack--text mt-3 mx-5">{{ $t('to') }}</span>
                    <FormSelect
                      :fieldValue.sync="formData.end_time"
                      required
                      :options="endTimeOptions"
                      dense
                      :readonly="!$validate.DataValid(formData.start_time)"
                      dispatchUpdateOnChange
                    />
                  </div>
                </v-col>
                <v-col class="py-0" cols="12" sm="6">
                  <FormSelect
                    label="tutor"
                    :fieldValue.sync="formData.tutor_id"
                    required
                    :options="tutorOptions"
                    dense
                    dispatchUpdateOnChange
                  />
                </v-col>
                <v-col class="py-0" cols="12" sm="6">
                  <FormSelect
                    label="classroom"
                    :fieldValue.sync="formData.classroom_id"
                    required
                    :options="classroomOptions"
                    dense
                    dispatchUpdateOnChange
                  />
                </v-col>
                <v-col class="py-0" cols="12" sm="6">
                  <FormFileUploader
                    uploadText="uploadNote"
                    fileInputKey="upload_lectures"
                    :files.sync="formData.attachment_list"
                    acceptFormat="imageWithPDF"
                    dispatchUpdateOnChange
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="pa-0 pl-lg-4 mb-4" cols="12" lg="6">
        <Datatable
          :isLoading="tableLoading"
          :tableData="studentTableData"
          :tableHeaders="studentTableHeaders"
          disableFooter
          disablePagination
          tableName="student"
          tableEmptyText="noStudent"
          enableExport
          @export-clicked="exportAttendance()"
          noWrapToolbar
        >
          <template slot="append-toolbar-left">
            <v-btn text depressed class="primary ml-1 my-2" @click.prevent="searchStudentOpen()" :loading="$store.getters.isLoading">{{ $t('addStudentForPackage') }}</v-btn>
          </template>
          <template v-slot:[`item.class_student_name`]="{ item }">
            <div class="d-flex align-center">
              <div class="gender-dot gender-m"></div>
              <div style="white-space: nowrap;" :class="{ 'red--text': item.unpaid, 'accent--text': !item.unpaid && item.last_lesson_id === id }">
                {{ item.class_student_name }}
              </div>
              <v-btn icon text depressed x-small :to="{ name: 'StudentDetails', params: { id: item.id } }">
                <v-icon class="fi fi-rr-eye mr-1" size="12" color="primary"></v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.attend_status`]="{ item }">
            <v-radio-group
              v-model="item.attend_status"
              row
              hide-details
              class="ma-0"
              dense
              @change="$store.dispatch('setDataIsUpdated', true)"
            >
              <v-radio v-if="item.attend_status === 'skip'" class="mr-2" :label="$t('attendanceStatus.skip')" value="skip"></v-radio>
              <template v-else>
                <v-radio class="mr-2" :label="$t('attendanceStatus.pending')" value="pending"></v-radio>
                <v-radio class="mr-2" :label="$t('attendanceStatus.attend')" value="attend"></v-radio>
                <v-radio class="mr-2" :label="$t('attendanceStatus.absent')" value="absent"></v-radio>
              </template>
            </v-radio-group>
          </template>
          <template v-slot:[`item.actionTransfer`]="{ item }">
            <div class="d-flex justify-end" v-if="item.attend_status !== 'skip'">
              <v-btn v-if="staticLessonInfo.regular_id > -1" class="accent colorBlack--text" text depressed small @click="skipLesson(item.id)">{{ $t('skipLesson') }}</v-btn>
              <v-btn class="accent colorBlack--text ml-2" text depressed small @click="changeLesson('all', item.id)">{{ $t('switchLesson') }}</v-btn>
              <v-btn class="accent colorBlack--text ml-2" text depressed small @click="changeLesson('single', item.id)">{{ $t('switchSingleLesson') }}</v-btn>
              <v-btn class="accent colorBlack--text ml-2" text depressed small @click="dropCourse(item.id)">{{ $t('dropCourse') }}</v-btn>
              <v-btn class="accent colorBlack--text ml-2" text depressed small @click="dropLesson(item.id)">{{ $t('dropLesson') }}</v-btn>
            </div>
          </template>
        </Datatable>

        <p class="text-caption mt-2 mb-0 colorBlack--text">{{ $t('studentNoPayTuition') }}</p>
        <p class="text-caption colorBlack--text">{{ $t('studentLastLesson') }}</p>
      </v-col>
    </v-row>

    <Datatable
      tableName="otherLesson"
      tableEmptyText="noOtherLesson"
      enableSelectAction
      enableSelectToggle
      :isLoading="otherLessonTableLoading"
      :tableData="otherLessonTableData"
      :tableHeaders="otherClassTableHeaders"
      disableFooter
      disablePagination
      @select-action-changes="handleSelectActionChange($event)"
      :selectedTableRow.sync="otherLessonSelectedRow"
      @edit-clicked="handleEditOpen($event)"
      @delete-clicked="handDeleteLesson($event)"
    ></Datatable>

    <v-row class="mt-4" v-if="staticLessonInfo.lesson_type === 'regular'">
      <v-col cols="12">
        <v-btn
          text
          depressed
          class="primary ml-2"
          @click.prevent="addMoreOtherLesson()"
          :loading="$store.getters.isLoading"
          :disabled="otherLessonTableLoading"
        >{{ $t('addOtherLesson') }}</v-btn>
      </v-col>
    </v-row>

    <!-- Edit 其他課堂 Dialog -->
    <FormDialog
      title="lessonInfo"
      :maxWidth="680"
      ref="editFormDialog"
      formRef="editForm"
      @submit-clicked="handleEditLessonSubmit()"
    >
      <v-row class="ma-0">
        <v-col class="py-0" cols="12" sm="6">
          <label class="input-label">{{ $t('time') }}</label>
          <div class="d-flex justify-space-between">
            <FormSelect
              :fieldValue.sync="editFormData.start_time"
              :required="editIsRequired"
              :options="startTimeOptions"
              dense
              @changed="editFormData.end_time = ''"
            />
            <span class="body-2 colorBlack--text mt-3 mx-5">{{ $t('to') }}</span>
            <FormSelect
              :fieldValue.sync="editFormData.end_time"
              :required="editIsRequired || $validate.DataValid(editFormData.start_time)"
              :options="editEndTimeOptions"
              dense
              :readonly="!$validate.DataValid(editFormData.start_time)"
            />
          </div>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col class="py-0" cols="12" sm="6">
          <FormSelect
            label="tutor"
            :fieldValue.sync="editFormData.tutor_id"
            :required="editIsRequired"
            :options="tutorOptions"
            dense
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="6">
          <FormSelect
            label="classroom"
            :fieldValue.sync="editFormData.classroom_id"
            :required="editIsRequired"
            :options="classroomOptions"
            dense
          />
        </v-col>
      </v-row>
    </FormDialog>

    <!-- 轉堂 Dialog -->
    <!-- 轉堂: 揀一堂後，將未上果N堂都轉去依個course到；如果有啲堂係下一個月，咁下一個月嘅果啲堂唔洗比錢
    轉堂(單一課堂): 轉一堂 -->
    <DialogLayout
      :openDialog="changeLessonDialogOpen"
      :maxWidth="910"
      :title="changeLessonFilter.type === 'single' ? 'switchSingleLesson' : 'switchLesson'"
      hideAction
      enableScroll
      cardContentClass="pa-0"
      @close="changeLessonDialogOpen = false"
    >
      <div class="pa-4">
        <v-row class="ma-0 my-4">
          <v-col cols="12" sm="6">
            <Searchbar
              label="searchCourse"
              :searchText.sync="changeLessonFilter.keyword"
              @search-clicked="onChangeLessonFilterChange()"
              @search-clear="onChangeLessonFilterChange()"
              :isSearching="isSearching"
              :searchType.sync="changeLessonFilter.keywordType"
              :searchTypeOptions="searchTypeOptions"
              enableMultiTypeSearch
              enabledClear
            />
          </v-col>

          <v-col cols="12" sm="6" v-if="changeLessonFilter.type === 'all'">
            <FormDatePicker
              label="lessonDate"
              placeholder="selectLessonDate"
              :dateValue.sync="changeLessonFilter.dateRange"
              isDateRange
              @changed="onChangeLessonFilterChange()"
              hideDetails
              dense
            />
          </v-col>
          <v-col cols="12" sm="6" v-else-if="changeLessonFilter.type === 'single'">
            <FormDatePicker
              label="lessonDate"
              placeholder="selectLessonDate"
              :dateValue.sync="changeLessonFilter.date"
              @changed="onChangeLessonFilterChange()"
              hideDetails
              dense
            />
          </v-col>

          <v-col cols="12" sm="6">
            <FormSelect
              label="tutor"
              placeholder="selectTutor"
              :fieldValue.sync="changeLessonFilter.tutor"
              :options="changeLessonTutorOptions"
              hideDetails
              :readonly="isSearching"
              dense
              @changed="onChangeLessonFilterChange()"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <FormSelect
              label="grade"
              placeholder="selectGrade"
              :fieldValue.sync="changeLessonFilter.grade"
              :options="$gradeOptions"
              hideDetails
              :readonly="isSearching"
              dense
              @changed="onChangeLessonFilterChange()"
            />
          </v-col>
        </v-row>
      </div>

      <Datatable
        :isLoading="isSearching"
        :tableData="searchTableData"
        :tableHeaders="changeLessonHeaders"
        :itemTotal="searchTableTotal"
        :page="searchTableOptions.tablePage"
        :pageLimit="searchTableOptions.tableLimit"
        :pageLimitOptions="searchTableOptions.limitOptions"
        @options-update="onSearchTableOptionsChange($event)"
        tableEmptyText="calendar.noLesson"
      >
        <template v-slot:[`item.actionSelectChangeLesson`]="{ item }">
          <div class="colorGrey--text pointer-none" v-if="item.applied === true">{{ $t('lessonStatus.applied') }}</div>
          <div class="colorGrey--text pointer-none" v-else-if="item.same === true">{{ $t('lessonStatus.same') }}</div>
          <v-btn
            v-else
            :loading="$store.getters.isLoading"
            depressed
            text
            color="primary"
            :ripple="false"
            class="pa-0"
            :class="{ 'pointer-none': $store.getters.isLoading }"
            style="min-width: unset !important;"
            @click="handleChangeLesson(item.id)"
          >{{ $t('switchLesson') }}</v-btn>
        </template>
      </Datatable>
    </DialogLayout>

    <!-- 新增其他課堂 Dialog -->
    <FormDialog
      title="addOtherLesson"
      :maxWidth="680"
      ref="addLessonFormDialog"
      formRef="addLessonForm"
      @submit-clicked="handleAddOtherLessonSubmit()"
    >
      <v-row class="ma-0">
        <v-col class="py-0" cols="12" sm="6">
          <FormSelect
            label="addLessonType"
            placeholder="selectAddLessonType"
            :fieldValue.sync="addOtherLessonType"
            :options="addOtherLessonTypeOptions"
            dense
            @changed="addOtherLessonValue = ''"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="6" v-if="addOtherLessonType === 'lesson_count'">
          <FormInput
            label="addLessonCount"
            :fieldValue.sync="addOtherLessonValue"
            type="number"
            :required="addOtherLessonType === 'lesson_count'"
            isIntegerOnly
            dense
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="6" v-else-if="addOtherLessonType === 'end_date'">
          <FormDatePicker
            label="courseEndDate"
            :dateValue.sync="addOtherLessonValue"
            dense
            :minDate="lastLessonDate"
            :required="addOtherLessonType === 'end_date'"
          />
        </v-col>
      </v-row>
    </FormDialog>

    <!-- 修改課堂 Dialog -->
    <FormDialog
      title="editLesson"
      :maxWidth="1100"
      ref="editClassFormDialog"
      formRef="editClassForm"
      @submit-clicked="handleEditClassSettingSubmit()"
    >
      <v-row class="ma-0">
        <v-col cols="12" sm="6" md="4" lg="3">
          <FormSelect
            label="course"
            placeholder="selectCourse"
            :fieldValue.sync="classData.course_id"
            :options="courseOptions"
            required
            dense
            :isLoading="courseOptionLoading"
          />
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <FormInput
            label="courseName"
            placeholder="courseName"
            :fieldValue.sync="classData.course_name"
            required
            dense
          />
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <FormInput
            type="number"
            label="price"
            placeholder="price"
            :fieldValue.sync="classData.price"
            dense
            prefix="HK$"
            suffix="perLesson"
            required
          />
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <FormSelect
            label="tutor"
            placeholder="selectTutor"
            :fieldValue.sync="classData.tutor_id"
            :options="tutorOptions"
            required
            dense
          />
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <FormSelect
            label="classroom"
            placeholder="selectClassroom"
            :fieldValue.sync="classData.classroom_id"
            :options="classroomOptions"
            required
            dense
          />
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <CourseColorPicker
            label="timetableColor"
            placeholder="selectTimetableColor"
            :fieldValue.sync="classData.color"
            required
          />
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <FormSelect
            label="salaryMethod"
            placeholder="selectSalaryMethod"
            :fieldValue.sync="classData.salary_method"
            :options="$salaryMethodOptions"
            required
            dense
          />
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <FormSelect
            label="studentUpperLimit"
            placeholder="selectStudentUpperLimit"
            :fieldValue.sync="classData.max_student"
            :options="maxStudentOptions"
            required
            dense
          />
        </v-col>
      </v-row>
    </FormDialog>

    <!-- 新增學生 Dialog -->
    <DialogLayout
      :openDialog="addStudentDialogOpen"
      :maxWidth="910"
      title="addStudentForPackage"
      hideAction
      enableScroll
      cardContentClass="pa-0"
      @close="addStudentDialogOpen = false"
    >
      <div class="pa-4">
        <v-row class="ma-0 my-4">
          <v-col cols="12" sm="6">
            <Searchbar
              :isSearching="isSearching"
              label="searchStudent"
              :searchText.sync="addStudentFilter.keyword"
              @search-clicked="onAddStudentFilterChange()"
              @search-clear="onAddStudentFilterChange()"
              :searchType.sync="addStudentFilter.keywordType"
              :searchTypeOptions="addStudentSearchTypeOptions"
              enableMultiTypeSearch
              enabledClear
            />
          </v-col>
        </v-row>
      </div>

      <Datatable
        :isLoading="isSearching"
        :tableData="addStudentSearchTableData"
        :tableHeaders="addStudentHeaders"
        :itemTotal="addStudentSearchTotal"
        :page="addStudentSearchTableOptions.tablePage"
        :pageLimit="addStudentSearchTableOptions.tableLimit"
        :pageLimitOptions="addStudentSearchTableOptions.limitOptions"
        @options-update="onAddStudentSearchTableOptionsChange($event)"
      >
        <template v-slot:[`item.actionAddStudent`]="{ item }">
          <v-btn
            v-if="!item.existed"
            :loading="$store.getters.isLoading"
            depressed
            text
            color="primary"
            :ripple="false"
            class="pa-0"
            :class="{ 'pointer-none': $store.getters.isLoading }"
            style="min-width: unset !important;"
            @click="handleAddStudent(item.id)"
          >{{ $t('add') }}</v-btn>
        </template>
      </Datatable>
    </DialogLayout>

    <ConfirmDialog ref="exceedStudentDialog" title="message.confirmToSwitchLesson" :isDeleteDialog="false" enableShowRemindCheckbox>{{ $t('message.reachedUpperLimitConfirmSwitch') }}</ConfirmDialog>
    <ConfirmDialog ref="changeLessonRemindDialog" title="message.confirmToSwitchLesson" :isDeleteDialog="false" enableShowRemindCheckbox>{{ $t('message.confirmToSwitchLessonMsg') }}</ConfirmDialog>
    <ConfirmDialog ref="dropCourseRemindDialog" title="message.confirmToDropCourse" :isDeleteDialog="false">{{ $t('message.confirmToDropCourseMsg') }}</ConfirmDialog>
    <ConfirmDialog ref="deleteOtherClassDialog" title="message.confirmToDeleteSelectedLesson" deleteDescription="message.confirmToDeleteSelectedLessonMsg"/>
    <ConfirmDialog ref="deleteThisLessonDialog" title="message.confirmToDeleteThisLesson" deleteDescription="message.confirmToDeleteThisLessonMsg" />
    <ConfirmDialog ref="editOtherClassDialog" title="message.confirmToEditSelectedLesson" :isDeleteDialog="false" enableShowRemindCheckbox>{{ $t('message.confirmToEditSelectedLessonMsg') }}</ConfirmDialog>
    <ConfirmDialog ref="confirmEditDialog" title="message.confirmEditLesson" :isDeleteDialog="false">{{ $t('message.confirmEditLessonMsg') }}</ConfirmDialog>
    <ConfirmDialog ref="skipLessonRemindDialog" title="message.confirmToSkipLesson" :isDeleteDialog="false">{{ $t('message.confirmToSkipLessonMsg') }}</ConfirmDialog>
    <ConfirmDialog ref="dropLessonRemindDialog" title="message.confirmToDropLesson" :isDeleteDialog="false">{{ $t('message.confirmToDropLessonMsg') }}</ConfirmDialog>
    <ConfirmDialog ref="addLessonForSkipDialog" title="message.targetCourseNotEnoughLesson" :isDeleteDialog="false" confirmBtnText="confirmAddLessonAndSkip" cancelBtnText="continueSkip">{{ $t('message.askConfirmToAddLessonForSkip') }}</ConfirmDialog>
  </v-container>
</template>

<script>
import { mdiClose, mdiFileTableOutline } from '@mdi/js'
import { mapActions } from 'vuex'
import FormDatePicker from '@/components/formField/FormDatePicker.vue'
import FormSelect from '@/components/formField/FormSelect.vue'
import Datatable from '@/components/Datatable.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import FormDialog from '@/components/FormDialog.vue'
import FormFileUploader from '@/components/formField/FormFileUploader.vue'
import DialogLayout from '@/components/layout/DialogLayout.vue'
import Searchbar from '@/components/Searchbar.vue'
import ExcelMixin from '@/mixins/ExcelMixin.vue'
import FormInput from '@/components/formField/FormInput.vue'
import CourseColorPicker from '@/components/CourseColorPicker.vue'

export default {
  name: 'ClassDetails',
  mixins: [ExcelMixin],
  components: {
    FormDatePicker,
    FormSelect,
    Datatable,
    ConfirmDialog,
    FormDialog,
    FormFileUploader,
    DialogLayout,
    Searchbar,
    FormInput,
    CourseColorPicker
  },
  computed: {
    id() {
      const id = parseInt(this.$route.params.id)
      if (!isNaN(id)) {
        return id
      }

      return null
    },
    pageTitle() {
      return `${this.staticLessonInfo.course_name} ${this.staticLessonInfo.date.replace(/-/g, "/")}${this.staticLessonInfo.date ? ` (${this.$d(new Date(this.staticLessonInfo.date), 'weekdayShort')})` : ''}`
    },
    endTimeOptions() {
      return this.$common.getEndTimeOptions(this.formData.start_time)
    },
    editEndTimeOptions() {
      return this.$common.getEndTimeOptions(this.editFormData.start_time)
    },
    timeSlotError() {
      return !this.$validate.DataValid(this.formData.start_time) || !this.$validate.DataValid(this.formData.end_time)
    },
  },
  data: (vm) => ({
    tableLoading: false,
    otherLessonTableLoading: false,
    exportLoading: false,
    tutorOptions: [],
    classroomOptions: [],
    staticLessonInfo: {
      course_id: -1,
      course_name: '',
      course_code: '',
      date: '',
      lesson_type: '',
      school_id: null,
      grade: '',
      regular_id: -1,
      package_used_student_id_list: [],

      price: '',
      tutor_id: '',
      classroom_id: '',
      color: '',
      salary_method: 'by_lesson',
      max_student: 1,
    },

    formData: {
      date: '',
      start_time: '',
      end_time: '',
      tutor_id: '',
      classroom_id: '',
      attachment_list: [],
    },

    studentTableData: [],
    rawAttendance: [],

    otherLessonTableData: [],
    otherLessonSelectedRow: [],
    otherLessonData: [],
    addOtherLessonValue: '',
    addOtherLessonType: 'lesson_count',
    lastLessonDate: '',
    showDelete: false,

    editIsRequired: false,
    editFormData: {
      start_time: '',
      end_time: '',
      tutor_id: '',
      classroom_id: '',
    },

    isSearching: false,
    searchTableData: [],
    searchTableTotal: 0,
    searchTableOptions: {
      limitOptions: [5, 10, 20],
      tableLimit: 5,
      tablePage: 1,
    },
    changeLessonDialogOpen: false,
    changeLessonStudentId: null,
    appliedLessonIds: [],
    changeLessonFilter: {
      type: '', // 'all' | 'single'
      keyword: '',
      keywordType: 'course_name',
      dateRange: [],
      date: '',
      tutor: '',
      grade: '',
    },
    changeLessonTutorOptions: [],

    classData: {
      course_id: -1,
      course_name: '',
      price: '',
      tutor_id: '',
      classroom_id: '',
      color: '',
      salary_method: 'by_lesson',
      max_student: 1,
    },
    courseOptions: [],
    maxStudentOptions: [],
    courseOptionLoading: false,

    addStudentDialogOpen: false,
    addStudentFilter: {
      keyword: '',
      keywordType: 'name',
    },
    addStudentSearchTableData: [],
    addStudentSearchTotal: 0,
    addStudentSearchTableOptions: {
      limitOptions: [5, 10, 20],
      tableLimit: 5,
      tablePage: 1,
    },

    // --- static data
    icon: {
      mdiClose,
      mdiFileTableOutline,
    },
    studentTableHeaders: [
      { value: 'class_student_name', text: vm.$t('studentName') },
      { value: 'attend_status', text: vm.$t('attendStatus'), width: vm.$i18n.locale === 'en' ? 350 : 250 },
      { value: 'switch_from', text: vm.$t('switchLessonRecord'), width: vm.$i18n.locale === 'en' ? 200 : 150 },
      { value: 'actionTransfer', text: '', align: 'end', width: vm.$i18n.locale === 'en' ? 780 : 530 },
    ],
    otherClassTableHeaders: [
      { value: 'course_name', text: vm.$t('course') },
      { value: 'weekday', text: vm.$t('weekday') },
      { value: 'full_date', text: vm.$t('date') },
      { value: 'timeslot', text: vm.$t('time') },
      { value: 'grade', text: vm.$t('grade') },
      { value: 'current_per_max_student_num', text: vm.$t('studentCount'), hasUnit: true },
      { value: 'minActionEditAndDelete', text: '' },
    ],
    startTimeOptions: [],
    changeLessonHeaders: [
      { value: 'course_name', text: vm.$t('purchaseItem') },
      { value: 'full_date', text: vm.$t('date'), hasWeekday: true },
      { value: 'timeslot', text: vm.$t('time') },
      { value: 'grade', text: vm.$t('grade') },
      { value: 'tutor', text: vm.$t('tutor') },
      { value: 'current_per_max_student_num', text: vm.$t('personCount') },
      { value: 'actionSelectChangeLesson', text: '', align: 'end' },
    ],
    searchTypeOptions: [
      { value: 'course_name', text: vm.$t('courseName') },
      { value: 'course_code', text: vm.$t('courseCode') },
    ],
    addOtherLessonTypeOptions: [
      { value: 'lesson_count', text: vm.$t('addLessonCount') },
      { value: 'end_date', text: vm.$t('courseEndDate') }
    ],
    addStudentSearchTypeOptions: [
      { value: 'name', text: vm.$t('studentName') },
      { value: 'phone', text: vm.$t('phone') },
    ],
    addStudentHeaders: [
      { value: 'student_code', text: vm.$t('studentCode') },
      { value: 'grade', text: vm.$t('grade') },
      { value: 'student_name', text: vm.$t('name') },
      { value: 'phone', text: vm.$t('phone') },
      { value: 'actionAddStudent', text: '', align: 'end' },
    ],
  }),
  methods: {
    ...mapActions(['setDialogMessage', 'setShowDialog', 'setLeaveDialogLink', 'setShowLeaveDialog']),
    async getLessonById() {
      this.tableLoading = true
      try {
        const data = await this.$Fetcher.GetLessonById(this.id, {
          join_student_data: true,
          join_student_last_lesson: false, // TODO
          join_attendance_data: true,
        })
        for (const key in this.formData) {
          if (this.$validate.DataValid(data[key])) {
            this.formData[key] = data[key]
          }
        }

        for (const key in this.staticLessonInfo) {
          if (this.$validate.DataValid(data[key])) {
            this.staticLessonInfo[key] = data[key]
          }
        }

        if (this.$validate.DataValid(data.attendance_list)) {
          this.rawAttendance = data.raw_attendance_list;
          this.studentTableData = data.attendance_list.map(el => {
            return {
              id: el.student_id,
              class_student_name: el.student_name,
              attend_status: el.status,
              gender: el.gender,
              switch_from: el.switch_from ? el.switch_from : 'N/A',
              unpaid: data.paid_student_ids.includes(el.student_id) ? false : true,
              last_lesson_id: el.last_lesson_id
            }
          })
        }

        await this.getTutorOptions()
        await this.getOtherLessons()
      } catch (err) {
        this.$common.error(err)
        this.setDialogMessage({
          message: 'noData',
          returnLink: { name: 'Timetable' },
        })
        this.setShowDialog(true)
      } finally {
        this.tableLoading = false
      }
    },
    async getTutorOptions() {
      try {
        let payload = {}
        if (this.$validate.DataValid(this.staticLessonInfo.school_id)) {
          payload.center = [this.staticLessonInfo.school_id]
        }

        const { data } = await this.$Fetcher.GetTutors(payload)
        this.tutorOptions = data.map(el => {
          return {
            value: el.id,
            text: el.name,
          }
        })

        this.changeLessonTutorOptions = [{ value: -1, text: this.$t('allTutors') }, ...this.tutorOptions]
      } catch (err) {
        this.$common.error(err)
      } finally {
        await this.getClassroomOptions()
      }
    },
    async getClassroomOptions() {
      try {
        let payload = {}
        if (this.$validate.DataValid(this.staticLessonInfo.school_id)) {
          payload.center_id = this.staticLessonInfo.school_id
        }

        const { data } = await this.$Fetcher.GetClassrooms(payload)
        this.classroomOptions = data.map(el => {
          return {
            value: el.id,
            text: el.name,
          }
        })
      } catch (err) {
        this.$common.error(err)
      } finally {
        this.$store.dispatch('toggleLoadingPage', false)
      }
    },

    // -- save 課堂資訊 & 學生 attendance
    async save() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      const valid = this.$refs['classInfoForm'].validate()
      if (!valid) {
        this.$store.dispatch('setLoading', false)
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'required',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      this.$store.dispatch('setLoading', true)
      const attendanceList = [];
      this.studentTableData.forEach(item => {
        const found = this.rawAttendance.find(el => el.student_id === item.id);
        if (found) {
          let temp = JSON.parse(JSON.stringify(found));
          temp.status = item.attend_status;
          attendanceList.push(temp);
        }
      })

      const payload = {
        date: this.formData.date,
        start_time: this.formData.start_time,
        end_time: this.formData.end_time,
        tutor_id: this.formData.tutor_id,
        classroom_id: this.formData.classroom_id,
        attachment_list: this.formData.attachment_list,
        attendance_list: attendanceList,
      }

      try {
        await this.$Fetcher.UpdateLesson(this.id, payload)

        this.$store.dispatch('setDataIsUpdated', false)
        this.$store.dispatch('setLoading', false)
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'message.saveSuccess',
          type: 'success',
          refresh: true,
          redirect: '',
        })
      } catch (err) {
        this.$common.error(err)
        this.setDialogMessage({
          title: 'message.saveFail',
          message: err,
          isError: true,
          returnLink: null,
        })
        this.setShowDialog(true)
        this.$store.dispatch('setLoading', false)
      }
    },

    // -- Delete 此課堂
    async handleDeleteThisLesson() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      const confirm = await this.$refs.deleteThisLessonDialog.show()
      if (confirm) {
        this.$store.dispatch('setLoading', true)

        try {
          const data = await this.$Fetcher.DeleteLesson([this.id])
          this.$store.dispatch('setLoading', false)

          if (data[0].info === 'not all pending' || data[0].info === 'paid lesson') {
            this.setDialogMessage({
              title: 'message.applyOccurFollowLessons',
              message: `${this.staticLessonInfo.course_name} (${this.staticLessonInfo.date})`,
              isError: true,
              returnLink: null,
            })
            this.setShowDialog(true)
          } else {
            this.$store.dispatch('setDataIsUpdated', false)
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: 'message.deleteSuccess',
              type: 'success',
              refresh: false,
              redirect: 'Timetable',
            })
          }
        } catch (err) {
          this.$common.error(err)
          this.setDialogMessage({
            title: 'message.deleteFail',
            message: err,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
          this.$store.dispatch('setLoading', false)
        }
      }
    },

    // ======= 課程其他課堂 =======
    async getOtherLessons() {
      this.otherLessonTableLoading = true
      try {
        const payload = await this.getOtherLessonsPayload()
        const { data } = await this.$Fetcher.GetLessons(payload)
        this.otherLessonData = data.filter(el => el.id !== this.id)
        this.otherLessonTableData = this.otherLessonData.map(el => {
          return {
            id: el.id,
            course_name: el.course_name,
            weekday: this.$formatter.getDay(el.date),
            date: el.date,
            grade: el.grade,
            start_time: el.start_time,
            end_time: el.end_time,
            current_student_num: el.current_student_num,
            max_student: el.max_student,
            tutor_id: el.tutor_id,
            classroom_id: el.classroom_id,
          }
        })
        this.$common.logData(this.otherLessonTableData, '課程其他課堂')

        if (this.otherLessonTableData.length > 0) {
          const d = new Date(this.otherLessonTableData[this.otherLessonTableData.length - 1].date);
          d.setDate(d.getDate() + 1);
          this.lastLessonDate = this.$formatter.formatDate(d);

          this.showDelete = false;
        } else {
          this.showDelete = true;
        }
      } catch (err) {
        this.$common.error(err)
        this.otherLessonData = []
        this.otherLessonTableData = []
        this.showDelete = true;
      } finally {
        this.otherLessonTableLoading = false
      }
    },
    getOtherLessonsPayload() {
      return {
        center_id: this.staticLessonInfo.school_id,
        filter_item: [
          { key: 'course_code', value: this.staticLessonInfo.course_code },
          { key: 'course_id', value: this.staticLessonInfo.course_id },
        ],
      }
    },

    async handleSelectActionChange(action) {
      if (!this.$validate.DataValid(this.otherLessonTableData)) {
        return
      }
      if (!this.$validate.DataValid(this.otherLessonSelectedRow)) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: action === 'delete' ? 'message.chooseLessonToDelete' : 'message.chooseLessonToEdit',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      if (action === 'edit') {
        this.handleEditOpen()
      } else if (action === 'delete') {
        this.handDeleteLesson()
      }
    },

    // -- Edit 其他課堂
    async handleEditOpen(id) {
      if (this.$store.getters.isLoading || (!this.$validate.DataValid(id) && !this.$validate.DataValid(this.otherLessonSelectedRow))) {
        return
      }

      this.editFormData.start_time = ''
      this.editFormData.end_time = ''
      this.editFormData.tutor_id = ''
      this.editFormData.classroom_id = ''
      await this.$refs['editFormDialog'].resetForm()

      if (this.$validate.DataValid(id)) {
        this.$refs['editFormDialog'].setEditId(id)
        this.editIsRequired = true

        const target = this.otherLessonTableData.find(el => el.id === id)
        const dupTarget = this.$common.duplicateData(target)
        if (dupTarget) {
          this.editFormData.start_time = dupTarget.start_time
          this.editFormData.end_time = dupTarget.end_time
          this.editFormData.tutor_id = dupTarget.tutor_id
          this.editFormData.end_time = dupTarget.end_time
          this.editFormData.classroom_id = dupTarget.classroom_id
        }
      } else {
        this.$refs['editFormDialog'].setEditId(null)
        this.editIsRequired = false
      }

      this.$refs['editFormDialog'].show()
    },
    async handleEditLessonSubmit() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      const confirm = await this.$refs.editOtherClassDialog.show();
      if (confirm) {
        const id = this.$refs['editFormDialog'].getEditId()
        const updateList = [];

        if (this.$validate.DataValid(id)) {
          const found = this.otherLessonTableData.find(el => el.id === id)
          if (found) {
            updateList.push({
              id: found.id,
              start_time: this.editFormData.start_time,
              end_time: this.editFormData.end_time,
              tutor_id: this.editFormData.tutor_id,
              classroom_id: this.editFormData.classroom_id
            });
          }
        } else if (this.$validate.DataValid(this.otherLessonSelectedRow)) {
          this.otherLessonSelectedRow.forEach(item => {
            const found = this.otherLessonTableData.find(el => el.id === item.id)
            if (found) {
              const obj = {
                id: found.id,
                start_time: found.start_time,
                end_time: found.end_time,
                tutor_id: found.tutor_id,
                classroom_id: found.classroom_id
              };

              if (
                this.$validate.DataValid(this.editFormData.start_time) &&
                this.$validate.DataValid(this.editFormData.end_time)
              ) {
                obj.start_time = this.editFormData.start_time
                obj.end_time = this.editFormData.end_time
              }

              if (this.$validate.DataValid(this.editFormData.tutor_id)) {
                obj.tutor_id = this.editFormData.tutor_id
              }

              if (this.$validate.DataValid(this.editFormData.classroom_id)) {
                obj.classroom_id = this.editFormData.classroom_id
              }

              updateList.push(obj);
            }
          })
        }
        this.$common.logData(updateList, 'Edit 其他課堂');

        if (updateList.length > 0) {
          const errors = await this.updateOtherLessons(updateList);
          if (errors.length) {
            const errorLessons = [];
            errors.forEach(id => {
              const found = this.otherLessonData.find(el => el.id === id)
              if (found) {
                errorLessons.push(`${found.course_name} (${found.date})`)
              }
            })

            this.setDialogMessage({
              title: 'message.errorOccurFollowLessons',
              message: errorLessons.join('<br>'),
              isError: true,
              returnLink: null,
            })
            this.setShowDialog(true)
          } else {
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: 'message.saveSuccess',
              type: 'success',
              refresh: false,
              redirect: '',
            })
          }
          this.getOtherLessons();
        }

        this.otherLessonSelectedRow = [];
        this.$store.dispatch('setLoading', false)
        this.$refs['editFormDialog'].hide();
      } else {
        this.$refs['editFormDialog'].setLoading(false);
      }
    },
    async updateOtherLessons(list, count = 0, errorList = []) {
      if (count < list.length) {
        try {
          const payload = {
            start_time: list[count].start_time,
            end_time: list[count].end_time,
            tutor_id: list[count].tutor_id,
            classroom_id: list[count].classroom_id,
          }
          await this.$Fetcher.UpdateLesson(list[count].id, payload)
        } catch (err) {
          this.$common.error(err)
          errorList.push(list[count].id)
        } finally {
          count += 1;
          return this.updateOtherLessons(list, count, errorList);
        }
      } else {
        return errorList;
      }
    },

    // -- Delete 其他課堂
    async handDeleteLesson(id) {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      const confirm = await this.$refs.deleteOtherClassDialog.show()
      if (confirm) {
        const deleteIds = [];
        if (this.$validate.DataValid(id)) {
          deleteIds.push(parseInt(id));
        } else {
          this.otherLessonSelectedRow.forEach(el => {
            deleteIds.push(el.id)
          })
        }

        this.$common.logData(deleteIds, 'Delete 其他課堂');
        if (deleteIds.length > 0) {
          this.$store.dispatch('setLoading', true);
          try {
            const data = await this.$Fetcher.DeleteLesson(deleteIds);
            const infoLessons = [];
            data.forEach(item => {
              if (item.info === 'not all pending' || item.info === 'paid lesson') {
                const found = this.otherLessonData.find(el => el.id === item.lesson);
                if (found) {
                  infoLessons.push(`${found.course_name} (${found.date})`)
                }
              }
            })
            if (infoLessons.length) {
              this.setDialogMessage({
                title: 'message.applyOccurFollowLessons',
                message: infoLessons.join('<br>'),
                isError: true,
                returnLink: null,
              })
              this.setShowDialog(true)
            } else {
              this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'message.deleteSuccess',
                type: 'success',
                refresh: false,
                redirect: '',
              })
            }
          
          } catch (err) {
            this.$common.error(err)
            this.setDialogMessage({
              title: 'message.deleteFail',
              message: err,
              isError: true,
              returnLink: null,
            })
            this.setShowDialog(true)
          } finally {
            this.otherLessonSelectedRow = [];
            this.$store.dispatch('setLoading', false);
            this.getOtherLessons();
          }
        }
      }
    },
    // async handDeleteLesson(id) {
    //   const confirm = await this.$refs.deleteOtherClassDialog.show()
    //   if (confirm) {
    //     const deleteIds = [];
    //     if (this.$validate.DataValid(id)) {
    //       deleteIds.push(parseInt(id));
    //     } else {
    //       this.otherLessonSelectedRow.forEach(el => {
    //         deleteIds.push(el.id)
    //       })
    //     }

    //     this.$common.logData(deleteIds, 'Delete 其他課堂');
    //     if (deleteIds.length > 0) {
    //       this.$store.dispatch('setLoading', true);
    //       const errors = await this.deleteOtherLessons(deleteIds);
    //       if (errors.length) {
    //         const errorLessons = [];
    //         errors.forEach(id => {
    //           const found = this.otherLessonData.find(el => el.id === id)
    //           if (found) {
    //             errorLessons.push(`${found.course_name} (${found.date})`)
    //           }
    //         })

    //         this.setDialogMessage({
    //           title: 'message.errorOccurFollowLessons',
    //           message: errorLessons.join('<br>'),
    //           isError: true,
    //           returnLink: null,
    //         })
    //         this.setShowDialog(true)
    //       } else {
    //         this.$store.dispatch('toggleAlertMessage', {
    //           show: true,
    //           message: 'message.deleteSuccess',
    //           type: 'success',
    //           refresh: false,
    //           redirect: '',
    //         })
    //       }
    //       this.getOtherLessons();
    //     }
    //     this.otherLessonSelectedRow = [];
    //     this.$store.dispatch('setLoading', false);
    //   }
    // },
    // async deleteOtherLessons(list, count = 0, errorList = []) {
    //   if (count < list.length) {
    //     try {
    //       await this.$Fetcher.DeleteLesson(list[count]);
    //     } catch (err) {
    //       this.$common.error(err)
    //       errorList.push(list[count].id)
    //     } finally {
    //       count += 1;
    //       return this.deleteOtherLessons(list, count, errorList);
    //     }
    //   } else {
    //     return errorList;
    //   }
    // },

    // -- 新增其他課堂
    async addMoreOtherLesson() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      this.$refs.addLessonFormDialog.show()
    },
    async handleAddOtherLessonSubmit() {
      this.$store.dispatch('setLoading', true)

      try {
        let payload = {};
        let addDays = 0;
        if (this.addOtherLessonType === 'end_date') {
          payload['end_date'] = this.addOtherLessonValue;
        } else {
          addDays = parseInt(this.addOtherLessonValue);
        }
        await this.$Fetcher.ExtendRegularLesson(this.staticLessonInfo.regular_id, addDays, payload)

        this.$store.dispatch('setLoading', false)
        this.$refs.addLessonFormDialog.hide()
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'message.createSuccess',
          type: 'success',
          refresh: false,
          redirect: '',
        })

        this.otherLessonTableLoading = true
        setTimeout(() => {
          this.getOtherLessons()
        }, 500)
      } catch (err) {
        this.$common.error(err)
        this.setDialogMessage({
          title: 'message.createFail',
          message: err,
          isError: true,
          returnLink: null,
        })
        this.setShowDialog(true)
        this.$store.dispatch('setLoading', false)
        this.$refs.addLessonFormDialog.setLoading(false)
      }
    },

    // ======= 轉堂/轉堂(單一課堂) =======
    async changeLesson(type, student_id) {
      this.changeLessonFilter.type = type
      this.changeLessonStudentId = student_id
      this.changeLessonFilter.grade = ''
      this.changeLessonFilter.keyword = ''
      this.changeLessonFilter.dateRange = []
      this.changeLessonFilter.date = ''
      this.changeLessonFilter.tutor = ''
      this.isSearching = true
      this.searchTableOptions.tablePage = 1
      this.searchTableData = []
      this.searchTableTotal = 0
      this.changeLessonDialogOpen = true

      // find student applied lesson
      try {
        const { data } = await this.$Fetcher.GetLessons({ filter_item: [{ key: 'student_id', value: student_id }] })
        const appliedLessons = data.filter(el => el.student_ids.includes(student_id));
        this.appliedLessonIds = appliedLessons.map(el => el.id)
      } catch (err) {
        this.$common.error(err)
      } finally {
        this.searchLesson()
      }
    },

    async searchLesson() {
      this.isSearching = true
      const payload = {
        filter_limit: this.searchTableOptions.tableLimit,
        filter_page: this.searchTableOptions.tablePage - 1,
        center_id: this.staticLessonInfo.school_id,
        join_tutor_data: true,
        filter_item: [],
      }

      if (this.$validate.DataValid(this.changeLessonFilter.tutor) && this.changeLessonFilter.tutor > -1) {
        payload.filter_item.push({ key: 'staff_id', value: this.changeLessonFilter.tutor })
      }

      if (
        this.$validate.DataValid(this.changeLessonFilter.keywordType) &&
        this.$validate.DataValid(this.changeLessonFilter.keyword)
      ) {
        payload.filter_item.push({ key: this.changeLessonFilter.keywordType, value: this.changeLessonFilter.keyword })
      }
      if (this.$validate.DataValid(this.changeLessonFilter.grade)) {
        payload.filter_item.push({ key: 'grade', value: this.changeLessonFilter.grade })
      }
      if (this.changeLessonFilter.type === 'all') {
        payload.filter_item.push({ key: 'lesson_type', value: 'regular' })
        if (this.$validate.DataValid(this.changeLessonFilter.dateRange)) {
          if (this.changeLessonFilter.dateRange[0]) {
            payload.filter_item.push({ key: 'start_date', value: this.changeLessonFilter.dateRange[0] })
          }
          if (this.changeLessonFilter.dateRange[1]) {
            payload.filter_item.push({ key: 'end_date', value: this.changeLessonFilter.dateRange[1] })
          }
        } else {
          const current_month = new Date()
          current_month.setDate(1)
          payload.filter_item.push({ key: 'start_date', value: this.$formatter.formatDate(current_month) })
        }
      } else if (this.changeLessonFilter.type === 'single') {
        if (this.$validate.DataValid(this.changeLessonFilter.date)) {
          payload.filter_item.push({ key: 'date', value: this.changeLessonFilter.date })
        } else {
          const current_month = new Date()
          current_month.setDate(1)
          payload.filter_item.push({ key: 'start_date', value: this.$formatter.formatDate(current_month) })
        }
      }

      try {
        const { data, total } = await this.$Fetcher.GetLessons(payload)
        this.searchTableTotal = total
        this.searchTableData = data.map(el => {
          return {
            id: el.id, // lesson id
            lesson_type: el.lesson_type,
            course_id: el.course_id,
            course_code: el.course_code,
            course_name: el.course_name,
            date: el.date,
            start_time: el.start_time,
            end_time: el.end_time,
            grade: el.grade,
            tutor: el.tutor_data.name,
            current_student_num: el.current_student_num,
            max_student: el.max_student,
            applied: this.$validate.DataValid(this.appliedLessonIds) && this.appliedLessonIds.includes(el.id),
            same: this.changeLessonFilter.type === 'all' && el.course_code === this.staticLessonInfo.course_code,
          }
        })
        this.isSearching = false
      } catch (err) {
        this.$common.error(err)
        this.isSearching = false
      }
    },

    onChangeLessonFilterChange() {
      this.searchTableOptions.tablePage = 1
      this.searchTableTotal = 0
      this.searchLesson()
    },

    async handleChangeLesson(lesson_id) {
      if (this.appliedLessonIds.includes(lesson_id)) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'message.lessonRepeat',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      const targetLesson = this.searchTableData.find(el => el.id === lesson_id)
      this.$common.logData(targetLesson, '轉堂')
      if (this.$validate.DataValid(targetLesson)) {
        if (targetLesson.current_student_num >= targetLesson.max_student) {
          const confirm = await this.$refs.exceedStudentDialog.show()
          if (!confirm) {
            return
          }
        } else {
          const confirm = await this.$refs.changeLessonRemindDialog.show()
          if (!confirm) {
            return
          }
        }

        this.$store.dispatch('setLoading', true)
        if (this.changeLessonFilter.type === 'all') {
          try {
            if (this.staticLessonInfo.package_used_student_id_list.includes(this.changeLessonStudentId)) {
              await this.$Fetcher.SwitchCourseWithPackage(this.changeLessonStudentId, this.staticLessonInfo.course_code, targetLesson.course_code, this.staticLessonInfo.date)
            } else {
              await this.$Fetcher.SwitchCourse(this.changeLessonStudentId, this.staticLessonInfo.course_code, targetLesson.course_code, this.staticLessonInfo.date)
            }

            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: 'message.switchLessonSuccess',
              type: 'success',
              refresh: true,
              redirect: '',
            })
            this.changeLessonDialogOpen = false
          } catch (err) {
            this.$common.error(err)
            this.setDialogMessage({
              title: 'message.switchLessonFail',
              message: err,
              isError: true,
              returnLink: null,
            })
            this.setShowDialog(true)
          } finally {
            this.$store.dispatch('setLoading', false)
          }
        } else if (this.changeLessonFilter.type === 'single') {
          try {
            if (this.staticLessonInfo.package_used_student_id_list.includes(this.changeLessonStudentId)) {
              await this.$Fetcher.SwitchLessonWithPackage(this.changeLessonStudentId, this.id, targetLesson.id)
            } else {
              await this.$Fetcher.SwitchLesson(this.changeLessonStudentId, this.id, targetLesson.id)
            }

            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: 'message.switchLessonSuccess',
              type: 'success',
              refresh: true,
              redirect: '',
            })
            this.changeLessonDialogOpen = false
          } catch (err) {
            this.$common.error(err)
            this.setDialogMessage({
              title: 'message.switchLessonFail',
              message: err,
              isError: true,
              returnLink: null,
            })
            this.setShowDialog(true)
          } finally {
            this.$store.dispatch('setLoading', false)
          }
        }
      } else {
        this.changeLessonDialogOpen = false
      }
    },

    onSearchTableOptionsChange(options) {
      if (options.itemsPerPage !== this.searchTableOptions.tableLimit) {
        this.searchTableOptions.tablePage = 1
      } else {
        this.searchTableOptions.tablePage = options.page
      }

      this.searchTableOptions.tableLimit = options.itemsPerPage
      this.searchLesson()
    },

    // ======= 退課 =======
    async dropCourse(studentId) {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      const confirm = await this.$refs.dropCourseRemindDialog.show()

      if (confirm) {
        this.$store.dispatch('setLoading', true)
        try {
          await this.$Fetcher.DropCourse(studentId, this.staticLessonInfo.course_code, this.staticLessonInfo.date)
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: 'message.dropCourseSuccess',
            type: 'success',
            refresh: true,
            redirect: '',
          })
        } catch (err) {
          this.$common.error(err)
          this.setDialogMessage({
            title: 'message.dropCourseFail',
            message: err,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      }
    },

    // ========= 匯出報表 =========
    async exportAttendance() {
      if (this.exportLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      this.exportLoading = true

      try {
        const payload = await this.getOtherLessonsPayload()
        const { data } = await this.$Fetcher.GetLessons({
          join_student_data: true,
          ...payload,
        })

        data.sort((a, b) => {
          const aDate = this.$formatter.convertStrToDate(a)
          const bDate = this.$formatter.convertStrToDate(b)
          return aDate.getTime() - bDate.getTime()
        })

        this.$common.logData(data, '匯出報表')

        let header = {
          student_name: '',
        }

        data.forEach(el => {
          const dateString = `${el.date.substring(8, 10)}/${el.date.substring(5, 7)}/${el.date.substring(0, 4)}`
          header[el.date] = dateString
        })

        const students = []
        data.forEach(el => {
          el.all_attendance_list.forEach(student => {
            const existed = students.find(e => e.id === student.student_id)
            if (!existed) {
              students.push({
                id: student.student_id,
                name: student.student_name,
              })
            }
          })
        })

        const exportData = students.map(el => {
          let obj = {}
          for (const key in header) {
            if (key === 'student_name') {
              obj['student_name'] = el.name
            } else {
              let status = ''
              const targetLesson = data.find(item => item.date === key)
              if (targetLesson) {
                const targetAttendance = targetLesson.all_attendance_list.find(e => e.student_id === el.id)
                if (targetAttendance) {
                  switch (targetAttendance.status) {
                    case 'attend':
                      status = 'Y'
                      break
                    case 'absent':
                      status = 'Absent'
                      break
                    case 'drop':
                      status = 'Drop'
                      break
                    case 'swap':
                      status = 'Swap'
                      break
                    case 'reschedule':
                      status = 'Reschedule'
                      break
                    case 'skip':
                      status = 'Skip'
                      break
                  }
                } else if (targetLesson.dropped_student_ids.includes(el.id)) {
                  status = 'Drop'
                }
              }
              obj[key] = status
            }
          }

          return obj
        })

        this.exportExcel(
          header,
          exportData,
          this.$t('attendStatus'),
          `${this.staticLessonInfo.course_code}_${this.staticLessonInfo.course_name}_attendance.xlsx`,
        )
      } catch (err) {
        this.$common.error(err)
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'message.exportFail',
          type: 'error',
          refresh: false,
          redirect: '',
        })
      } finally {
        this.exportLoading = false
      }
    },

    // ======= 修改課堂 =======
    async editClassSetting() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      for (const key in this.classData) {
        if (this.staticLessonInfo[key] !== undefined) {
          this.classData[key] = this.staticLessonInfo[key]
        }
      }

      this.$refs.editClassFormDialog.show()
      if (!this.courseOptions.length) {
        await this.getCourseOptions();
      }
    },
    async getCourseOptions() {
      this.courseOptionLoading = true;
      try {
        let payload = {
          join_category_data: true,
        }
        const selectedSchool = await this.getUserSelectedSchool()
        if (this.$validate.DataValid(selectedSchool)) {
          payload.center_id = selectedSchool
        }

        const { data } = await this.$Fetcher.GetCourses(payload)
        this.courseOptions = data.map(el => {
          return {
            value: el.id,
            text: this.$formatter.generateCourseTitle(el),
            grade: el.grade
          }
        })
      } catch {} finally {
        this.courseOptionLoading = false;
      }
    },
    async handleEditClassSettingSubmit() {
      const confirm = await this.$refs.confirmEditDialog.show();
      if (confirm) {
        this.$store.dispatch('setLoading', true)

        try {
          let payload = {
            course_id: parseInt(this.classData.course_id),
            course_code: this.staticLessonInfo.course_code,
            course_name: this.classData.course_name,
            tutor_id: parseInt(this.classData.tutor_id),
            classroom_id: parseInt(this.classData.classroom_id),
            color: this.classData.color,
            salary_method: this.classData.salary_method,
            max_student: parseInt(this.classData.max_student),
            price: parseFloat(this.classData.price),
          };
          const foundCourse = this.courseOptions.find(el => el.value == parseInt(this.classData.course_id));
          if (foundCourse) {
            payload['grade'] = foundCourse.grade;
          }

          if (this.staticLessonInfo.regular_id > -1) {
            await this.updateRegularSetting(payload);
          }

          const allLessons = this.otherLessonData.map(el => el.date);
          allLessons.push(this.staticLessonInfo.date);
          allLessons.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
          const d = new Date(allLessons[0]);
          d.setDate(d.getDate() - 1);
          payload['course_code'] = this.staticLessonInfo.course_code;
          payload['date'] = this.$formatter.formatDate(d);
          await this.$Fetcher.UpdateLessonSetting(this.id, payload);

          this.$store.dispatch('setLoading', false)
          this.$refs.editClassFormDialog.hide()
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: 'message.updateSuccess',
            type: 'success',
            refresh: true,
            redirect: '',
          })
        } catch (err) {
          this.$common.error(err)
          this.setDialogMessage({
            title: 'message.updateFail',
            message: err,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
          this.$store.dispatch('setLoading', false)
          this.$refs.editClassFormDialog.setLoading(false)
        }
      } else {
        this.$refs.editClassFormDialog.setLoading(false);
      }
    },
    updateRegularSetting(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          await this.$Fetcher.UpdateRegularSetting(this.staticLessonInfo.regular_id, payload);
          resolve(true);
        } catch(err) {
          this.$common.error(err)
          reject(`[REGULAR] ${err}`);
        }
      })
    },

    // ======= 新增學生(套票專用) =======
    searchStudentOpen() {
      this.addStudentFilter.keyword = '';
      this.addStudentFilter.keywordType = 'name';
      this.addStudentSearchTableData = [];
      this.addStudentSearchTotal = 0;
      this.addStudentSearchTableOptions.tablePage = 1;
      this.addStudentDialogOpen = true;
      this.searchStudents();
    },

    async searchStudents() {
      let payload = {
        filter_limit: this.addStudentSearchTableOptions.tableLimit,
        filter_page: this.addStudentSearchTableOptions.tablePage - 1,
        center_id: this.staticLessonInfo.school_id,
        filter_item: [],
      }
      if (this.$validate.DataValid(this.addStudentFilter.keyword)) {
        const keyword = this.addStudentFilter.keyword.trim()

        if (this.$validate.DataValid(this.addStudentFilter.keywordType)) {
          payload.filter_item.push({ key: this.addStudentFilter.keywordType, value: keyword })
        } else {
          payload.search = keyword
        }
      } else {
        payload.filter_item.push({ key: 'grade', value: this.staticLessonInfo.grade })
      }

      this.isSearching = true;
      try {
        const { data, total } = await this.$Fetcher.GetStudents(payload)
        this.addStudentSearchTableData = data.map(el => {
          const existed = this.studentTableData.find(elm => elm.id === el.id);
          return {
            id: el.id,
            student_id: el.id,
            student_code: el.student_code,
            grade: el.grade,
            student_name: el.student_name,
            phone: el.phone,
            existed: existed ? true : false,
            school_id: el.school_id
          }
        })
        this.addStudentSearchTotal = total
      } catch {
        this.addStudentSearchTableData = [];
      } finally {
        this.isSearching = false;
      }
    },

    onAddStudentFilterChange() {
      this.addStudentSearchTableOptions.tablePage = 1
      this.addStudentSearchTotal = 0
      this.searchStudents()
    },

    onAddStudentSearchTableOptionsChange(options) {
      if (options.itemsPerPage !== this.addStudentSearchTableOptions.tableLimit) {
        this.addStudentSearchTableOptions.tablePage = 1
      } else {
        this.addStudentSearchTableOptions.tablePage = options.page
      }

      this.addStudentSearchTableOptions.tableLimit = options.itemsPerPage
      this.searchStudents()
    },

    async handleAddStudent(id) {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      this.$store.dispatch('setLoading', true)
      try {
        const quota = await this.$Fetcher.GetStudentPackageQuota(parseInt(id));
        this.$store.dispatch('setLoading', false)
        this.addStudentDialogOpen = false;
        this.addStudentToLesson(parseInt(id), quota);
      } catch(err) {
        this.$common.error(err)
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'message.noMorePackageQuota',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        this.$store.dispatch('setLoading', false)
      }
    },
    async addStudentToLesson(studentId, quota) {
      this.$store.dispatch('setLoading', true)
      try {
        if (quota > 0) {
          await this.$Fetcher.AddStudentToLessonWithPackage(this.id, studentId);
        } else {
          await this.$Fetcher.AddStudentToLesson(this.id, studentId);
        }
        
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'message.createSuccess',
          type: 'success',
          refresh: false,
          redirect: '',
        })
        this.getStudentAttendance();
        this.$store.dispatch('setLoading', false)
      } catch(err) {
        this.$common.error(err)
        this.setDialogMessage({
          title: 'message.createFail',
          message: err,
          isError: true,
          returnLink: null,
        })
        this.setShowDialog(true)
        this.$store.dispatch('setLoading', false)
      }
    },
    
    async getStudentAttendance() {
      this.tableLoading = true;
      try {
        const data = await this.$Fetcher.GetLessonById(this.id, {
          join_student_data: true,
          join_student_last_lesson: false, // TODO
          join_attendance_data: true,
        })
        this.staticLessonInfo.package_used_student_id_list = data.package_used_student_id_list;
        if (this.$validate.DataValid(data.attendance_list)) {
          this.rawAttendance = data.raw_attendance_list;
          this.studentTableData = data.attendance_list.map(el => {
            return {
              id: el.student_id,
              class_student_name: el.student_name,
              attend_status: el.status,
              gender: el.gender,
              switch_from: el.switch_from ? el.switch_from : 'N/A',
              unpaid: data.paid_student_ids.includes(el.student_id) ? false : true,
              last_lesson_id: el.last_lesson_id
            }
          })
        } else {
          this.studentTableData = [];
        } 
      } catch (err) {
        this.$common.error(err)
        this.studentTableData = [];
      } finally {
        this.tableLoading = false
      }
    },

    // ======= 跳堂 =======
    async skipLesson(studentId) {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      const confirm = await this.$refs.skipLessonRemindDialog.show();
      if (confirm) {
        this.$store.dispatch('setLoading', true)
        if (this.staticLessonInfo.package_used_student_id_list.includes(studentId)) {
          try {
            await this._handleSkipLesson(studentId);
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: 'message.saveSuccess',
              type: 'success',
              refresh: false,
              redirect: '',
            })
            this.getStudentAttendance();

          } catch(err) {
            this.$common.error(err)
            if (err === 'to missing') {
              this.handleConfirmAddMoreLesson(studentId);
            } else {
              this.setDialogMessage({
                title: 'message.saveFail',
                message: err,
                isError: true,
                returnLink: null,
              })
              this.setShowDialog(true)
            }
          } finally {
            this.$store.dispatch('setLoading', false)
          }
        } else {
          try {
            await this._handleSkipLesson(studentId);
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: 'message.saveSuccess',
              type: 'success',
              refresh: false,
              redirect: '',
            })
            this.getStudentAttendance();
          } catch (err) {
            this.setDialogMessage({
              title: 'message.saveFail',
              message: err,
              isError: true,
              returnLink: null,
            })
            this.setShowDialog(true)
          } finally {
            this.$store.dispatch('setLoading', false)
          }
        }
      }
    },

    async handleConfirmAddMoreLesson(studentId) {
      const confirm = await this.$refs['addLessonForSkipDialog'].show();
      if (confirm) {
        // -- add one more lesson -> skip_lesson
        this.$store.dispatch('setLoading', true)
        try {
          await this.$Fetcher.ExtendRegularLesson(this.staticLessonInfo.regular_id, 1, {});
          await this._handleSkipLesson(studentId);

          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: 'message.saveSuccess',
            type: 'success',
            refresh: false,
            redirect: '',
          })
          this.getStudentAttendance();
        } catch(err) {
          this.$common.error(err)
          this.setDialogMessage({
            title: 'message.saveFail',
            message: err,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      } else {
        // -- update lesson student attendance
        const attendanceList = this.rawAttendance.map(item => {
          let temp = JSON.parse(JSON.stringify(item));
          if (item.student_id === studentId) {
            temp.status = 'skip';
          }
          return temp;
        })

        const payload = {
          attendance_list: attendanceList
        }
        try {
          await this.$Fetcher.UpdateLesson(this.id, payload)
          this.$store.dispatch('setLoading', false)
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: 'message.saveSuccess',
            type: 'success',
            refresh: false,
            redirect: '',
          })
          this.getStudentAttendance();
        } catch (err) {
          this.$common.error(err)
          this.setDialogMessage({
            title: 'message.saveFail',
            message: err,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
          this.$store.dispatch('setLoading', false)
        }
      }
    },

    async _handleSkipLesson(studentId) {
      return new Promise(async (resolve, reject) => {
        try {
          await this.$Fetcher.SkipLesson(this.id, studentId)
          resolve(true);
        } catch (err) {
          this.$common.error(err)
          reject(err);
        }
      })
    },

    // ======= 退課（單一課堂） =======
    async dropLesson(studentId) {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      const confirm = await this.$refs.dropLessonRemindDialog.show();
      if (confirm) {
         this.$store.dispatch('setLoading', true)
         try {
            await this.$Fetcher.DropLesson(this.id, studentId)
            this.$store.dispatch('setLoading', false)
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: 'message.saveSuccess',
              type: 'success',
              refresh: false,
              redirect: '',
            })
            this.getStudentAttendance();
          } catch (err) {
            this.$common.error(err)
            this.setDialogMessage({
              title: 'message.saveFail',
              message: err,
              isError: true,
              returnLink: null,
            })
            this.setShowDialog(true)
            this.$store.dispatch('setLoading', false)
          }
      }
    },


    async handleSiteLoaded() {
      console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>> handleSiteLoaded: ClassDetails')
      if (this.$validate.DataValid(this.id)) {
        // edit
        await this.getLessonById()
      }
    },
  },

  created() {
    const list = []
    for (let i = 1; i <= 30; i++) {
      list.push({
        value: i,
        text: this.$t('person', { count: i }),
      })
    }
    this.maxStudentOptions = list
    this.startTimeOptions = this.$common.getStartTimeOptions()
  },

  destroyed() {
    window.removeEventListener('onSiteLoaded', this.handleSiteLoaded)
  },
  mounted() {
    this.$store.dispatch('toggleLoadingPage', true)
    this.tableLoading = true
    this.otherLessonTableLoading = true
    window.addEventListener('onSiteLoaded', this.handleSiteLoaded)
  },

  // ------ navigation guard ------
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.isLoading) {
      this.$store.dispatch('toggleAlertMessage', {
        show: true,
        message: 'processing',
        type: 'error',
        refresh: false,
        redirect: '',
      })
      next(false)
    } else if (this.$store.getters.dataIsUpdated) {
      this.setLeaveDialogLink({ name: to.name })
      this.setShowLeaveDialog(true)
      next(false)
    } else {
      next()
    }
  },
}
</script>

<style lang="scss" scoped>
.course-info {
  & .v-card__title {
    background-color: #ffffff !important;
    color: var(--v-colorBlack-base) !important;
    font-size: 0.875rem;
    font-weight: 500;
  }

  & .course-code {
    background-color: var(--v-primary-base);
    color: var(--v-colorWhite-base) !important;
    font-size: 0.875rem;
    font-weight: 500;
  }
}
</style>
