<template>
  <DialogLayout
    :openDialog="open"
    :maxWidth="maxWidth"
    :title="title"
    :btnAlign="btnAlign"
    enableScroll
    @close="_dialogAction"
    eager
    :loading="formLoading"
    :cardContentClass="cardContentClass"
    :enableCancel="enableCancel"
    :confirmBtnText="confirmBtnText"
    :cancelBtnText="cancelBtnText"
  >
    <v-form class="mt-4" :ref="formRef" @submit.prevent>
      <slot></slot>
    </v-form>
  </DialogLayout>
</template>

<script>
import DialogLayout from '@/components/layout/DialogLayout.vue'
export default {
  name: 'FormDialog',
  components: {
    DialogLayout,
  },
  props: {
    formRef: {
      type: String,
      required: true,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: Number,
      default: 500,
    },
    btnAlign: {
      type: String,
      default: 'start',
    },
    cardContentClass: {
      type: String,
      default: '',
    },
    enableCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    confirmBtnText: {
      type: String,
      required: false,
      default: 'confirm',
    },
    cancelBtnText: {
      type: String,
      required: false,
      default: 'cancel',
    },
    disableCloseHide: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    open: false,
    formLoading: false,
    editId: null,
  }),
  methods: {
    show() {
      this.open = true
    },
    hide() {
      this.open = false
      this.formLoading = false
      this.setEditId(null)
    },
    setLoading(loading) {
      this.formLoading = loading
    },
    setEditId(id) {
      this.editId = id
    },
    getEditId() {
      return this.editId
    },
    async resetForm() {
      await this.$refs[this.formRef].reset();
    },

    async _dialogAction(action) {
      if (this.formLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      if (action === true) {
        const valid = await this.$refs[this.formRef].validate()
        if (!valid) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: 'required',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          return
        }

        this.formLoading = true
        this.$emit('submit-clicked', true)
      } else {
        if (!this.disableCloseHide) {
          this.hide()
        }
        
        this.$emit('close')
      }
    },
  },
}
</script>
